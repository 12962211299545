<template>
    <v-card-text>
        <crud
            label="My Abbreviations"
            model="abbreviations"
            :headers="headers"
            :conditions="[{client_id:{eq:$store.state.user.payload.id}}, {deleted:{'eq':'0'}}]"
            :initialValues="{client_id:$store.state.user.payload.id}"
        >
        </crud>
    </v-card-text>
</template>

<script>
import crud from "../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Abbreviation", value:"abbrv", mandatory: true, type: "uppercasetext", sm:4},
                {text:"Phrase", value:"phrase", mandatory: true, sm:8},
            ]
        }
    }
}
</script>