<template>
    <v-text-field v-bind="$attrs" v-on="$listeners" v-model="model" :label="label">
        <template v-if="mandatory" v-slot:label>
             <span style="color:red">* {{label}}</span>
        </template>
    </v-text-field>
</template>

<script>
export default {
    props: [
        "mandatory",
        "label",
    ],
    data() {
        return {
            model: "",
        }
    },
    watch: {
        model: {
            handler(n) {
                if(n) {
                    this.model = n.toUpperCase();
                }
                else  {
                    this.model = null;
                }
            }
        }
    }
}
</script>